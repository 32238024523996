import { SeevClient } from "@/lib/SeevClient";

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const prodFeatureFlags = {
  enableCapture: false,
  enableFileManager: true,
  enableAnalytics: true,
  enableInconsistencies: false,
  enableOpportunities: false,
  enableTopBar: true,
  enableSentryFeedback: true,
  enableSignup: false,
  enableProjectFileUpdate: false,
  enableProposal: false,
  enableForms: false,
};

const prodBasicFeatureFlags = {
  enableCapture: false,
  enableFileManager: true,
  enableAnalytics: false,
  enableInconsistencies: false,
  enableOpportunities: false,
  enableTopBar: true,
  enableSentryFeedback: true,
  enableSignup: false,
  enableProjectFileUpdate: false,
  enableProposal: false,
  enableForms: false,
};

const prodBasicOpportunitiesFeatureFlags = {
  enableCapture: false,
  enableFileManager: true,
  enableAnalytics: false,
  enableInconsistencies: false,
  enableOpportunities: true,
  enableTopBar: true,
  enableSentryFeedback: false,
  enableSignup: false,
  enableProjectFileUpdate: false,
  enableProposal: false,
  enableForms: false,
};

const prodPremiumFeatureFlags = {
  enableCapture: false,
  enableFileManager: true,
  enableAnalytics: false,
  enableInconsistencies: false,
  enableOpportunities: true,
  enableTopBar: true,
  enableSentryFeedback: false,
  enableSignup: false,
  enableProjectFileUpdate: false,
  enableProposal: false,
  enableForms: true,
};

const devFeatureFlags = {
  enableCapture: false,
  enableFileManager: true,
  enableAnalytics: true,
  enableInconsistencies: false,
  enableOpportunities: true,
  enableTopBar: true,
  enableSentryFeedback: true,
  enableSignup: true,
  enableProjectFileUpdate: true,
  enableProposal: true,
  enableForms: true,
};

export const featureFlags = () => {
  // DevAtProd
  if (SeevClient.authManager.organizationId === "66bba340cf0d0e1b2ff8764c") {
    return prodFeatureFlags;
  }

  // TODO: Find a better way to handle this
  if (SeevClient.authManager.organizationId === "6743be1ea8acd5e1d9f2658d") {
    return prodBasicFeatureFlags;
  }

  if (SeevClient.authManager.organizationId === "674c8a270f6e839600480b71") {
    return prodBasicOpportunitiesFeatureFlags;
  }

  if (SeevClient.authManager.organizationId === "67546f490f6e83960048167b") {
    return prodPremiumFeatureFlags;
  }

  if (
    ENVIRONMENT === "dev" ||
    ENVIRONMENT === "local" ||
    // ProdAtDev
    SeevClient.authManager.organizationId === "66bb9b4d426d1cd33aed536e" ||
    // Prod Demo Org
    SeevClient.authManager.organizationId === "66f2f85fa524d90f59560516"
  ) {
    return devFeatureFlags;
  }

  return prodFeatureFlags;
};
